import { Link } from '@remix-run/react'
import { Text } from './text.tsx'

export const Footer = () => {
	return (
		<Text variant="Text3" tagType="h1" className="flex flex-col items-center justify-center gap-2 rounded-t-full bg-gradient-to-b from-primary to-primary-degrade p-12 font-medium text-white lg:flex-row lg:p-16 3xl:p-24 4xl:p-32">
			<Link to="/contact-us" className="decoration-white hover:underline ">
				Contact
			</Link>
			<span>•</span>
			<Link to="/legalMentions" className="decoration-white hover:underline ">
				Mentions légales
			</Link>
			<span>•</span>
			<Link to="/privacyPolicy" className="decoration-white hover:underline ">
				Politique de confidentialité
			</Link>
			<span>•</span>
			<Link to="/cgvu" className="decoration-white hover:underline ">
				Conditions générales de vente et d'utilisation
			</Link>
		</Text>
	)
}
