import { Link, NavLink } from '@remix-run/react'
import { useState } from 'react'
import logo from '~/assets/logo/logoblanc2.png'
import { Button } from '~/components/ui/Button.tsx'
import { useUrls } from '~/constants/navUrls.ts'
import { type UserWithIncludes } from '~/models/users.server.ts'

export function Header({ user }: { user: UserWithIncludes | null }) {
	const { dashboardUrl } = useUrls()
	const [isMenuOpen, setIsMenuOpen] = useState(false)


	const toggleMenu = () => {
		setIsMenuOpen(!isMenuOpen)
	}

	const closeMenu = () => {
		setIsMenuOpen(false)
	}

	const activeLink = 'font-semibold text-white py-2 px-3 cursor-pointer border-white  border-b-4 transition'
	const normalLink = 'font-semibold text-white py-2 px-3 cursor-pointer border-white hover:border-b-4 transition'

	return (
		<div className="fixed top-0 z-50 flex w-full justify-between bg-primary px-5 py-2 shadow-lg lg:items-center">
			<img src={logo} alt="logo blanc zamas" className={`-mt-1.5 h-8 lg:h-12 4xl:h-16`} />
			<div className={`flex-col items-center justify-center lg:flex-row ${isMenuOpen ? 'flex' : 'hidden'} gap-2 pb-8 text-xs tracking-wide md:text-sm lg:flex lg:pb-0 xl:gap-4 2xl:gap-8 2xl:text-base 3xl:gap-12 3xl:text-lg 4xl:text-xl`}>
				<NavLink to="/" className={({ isActive }) => (isActive ? activeLink : normalLink)} onClick={closeMenu}>
					ACCUEIL
				</NavLink>
				<NavLink to="/team" className={({ isActive }) => (isActive ? activeLink : normalLink)} onClick={closeMenu}>
					L'EQUIPE
				</NavLink>
				<NavLink to="/project" className={({ isActive }) => (isActive ? activeLink : normalLink)} onClick={closeMenu}>
					NOTRE OFFRE
				</NavLink>
				<NavLink to="/contact-us" className={({ isActive }) => (isActive ? activeLink : normalLink)} onClick={closeMenu}>
					CONTACTEZ-NOUS
				</NavLink>

				{user ? (
					<Button asChild variant="primary-reverse" size="base" className="mt-4 rounded-full lg:hidden" onClick={closeMenu}>
						<Link to={dashboardUrl}>Vers l'application</Link>
					</Button>
				) : (
					<Button asChild variant="primary-reverse" size="base" className="mt-4 rounded-full lg:hidden" onClick={closeMenu}>
						<Link to="/login">Se connecter à l'application</Link>
					</Button>
				)}
			</div>
			<div className="relative h-6 w-8 cursor-pointer lg:hidden" onClick={toggleMenu}>
				{/* Hamburger menu */}
				<div className={`relative h-8 w-8 cursor-pointer`} />
				<span
					className={`absolute left-0 top-0 h-[2px] w-full bg-white transition-all duration-300
						${isMenuOpen ? 'translate-y-3 rotate-45' : ''}`}
				/>
				<span
					className={`absolute bottom-1/2 left-0 h-[1.5px] w-full bg-white transition-all duration-300
						${isMenuOpen ? 'opacity-0' : ''}`}
				/>
				<span
					className={`absolute bottom-0 left-0 h-[2px] w-full bg-white transition-all duration-300
						${isMenuOpen ? '-translate-y-2.5 -rotate-45' : ''}`}
				/>
			</div>

			{user ? (
				<Button asChild variant="primary-reverse" size="base" onClick={closeMenu} className="mr-2 hidden rounded-full text-lg font-semibold lg:inline-flex">
					<Link to={dashboardUrl}>Vers l'application</Link>
				</Button>
			) : (
				<Button asChild variant="primary-reverse" size="base" className="mr-2 hidden rounded-full lg:inline-flex" onClick={closeMenu}>
					<Link to="/login">Se connecter à l'application</Link>
				</Button>
			)}
		</div>
	)
}
